import React from "react";
import Section from "../../components/section";
import { PageHero } from "../../components/page-hero";
import Layout from "../../components/layout/layout";
import Tabs from "../../components/tabs";
const StepanImg =
  "https://storage.googleapis.com/website.hexometer.com/team/Stepan.jpg";
const ChrisImg =
  "https://storage.googleapis.com/website.hexometer.com/team/Chris.jpg";
const VaheImg =
  "https://storage.googleapis.com/website.hexometer.com/team/Vahe.jpg";
const LusineImg =
  "https://storage.googleapis.com/website.hexometer.com/team/Lusine.jpg";

import "./style.scss";

const peoples = [
  {
    name: "Stepan",
    position: "CEO",
    img: StepanImg,
    description:
      "Stepan is an entrepreneurial catalyst. In a previous life, he co-founded one of the early pioneers of food delivery services now popularised by the likes of Uber Eats. Now his mission is to re-invent the very concept of website measuring and monitoring to help even the playing field for businesses of all sizes."
  },
  {
    name: "Chris",
    position: "CMO & co-founder",
    img: ChrisImg,
    description:
      "Chris is an eCommerce maverick who loves to challenge the status quo. As a natural born possibilitarian with a healthy dose of OCD, he brings vision, strategy and a keen attention to detail."
  },
  {
    name: "Vahe",
    position: "CTO & co-founder",
    img: VaheImg,
    description:
      "Vahe is a startup veteran with a decade of experience coding and architecting scalable platforms. He leads our epic development team to keep us at the bleeding edge."
  },
  {
    name: "Lusine",
    position: "Project manager",
    img: LusineImg,
    description:
      "As they say, teamwork makes for dream work. Lusine is our in-house chatterbox, who helps us run an agile ship, ensuring all our teams are talking to each other and delivering on time."
  }
];
import { Container, Row, Col } from "react-bootstrap";
import waves from "../../images/teamImages/waves-icon.svg";
import dotsIcon from "../../images/teamImages/dots-icon.svg";
import shape1 from "../../images/teamImages/shape1.svg";
import shape2 from "../../images/teamImages/shape2.svg";
import shape3 from "../../images/teamImages/shape3.svg";
import shape4 from "../../images/teamImages/shape4.svg";
import ellipse from "../../images/teamImages/ellipse.svg";

import teamImg from "../../images/teamImages/team-main-image.jpg";
import teamImg1 from "../../images/teamImages/team-image-1.png";
import teamImg2 from "../../images/teamImages/team-image-2.png";
import WavesShapeIcon from "../../images/teamImages/WavesShapeIcon";
import Button from "../../components/button";

const Team: React.FC = (): JSX.Element => {
  return (
    <div className="shapes-wrapper" style={{ position: "relative" }}>
      <img src={dotsIcon} className="dots-icon-1 display" alt="" />
      <img src={dotsIcon} className="dots-icon-2 display" alt="" />
      <img src={dotsIcon} className="dots-icon-3 display" alt="" />

      <img src={shape1} className="shape-1 display" alt="" />
      <img src={shape2} className="shape-2 display" alt="" />
      <img src={shape3} className="shape-3 display" alt="" />

      <Layout>
        <PageHero
          title="Meet the Hexowatch team"
          subtitle="Here's to the crazy ones. The misfits. 
      The rebels. The troublemakers. The round pegs in the square holes.
       The ones who see things differently, working tirelessly behind the scenes so you can change the world."
        />

        <div style={{ margin: "auto" }}>
          <Tabs />
        </div>

        <div className="team-main-wrapper">
          <section className="header-image-section">
            <Container>
              <img src={dotsIcon} className="dots-icon-header display" alt="" />
              <div className="ellipse">
                <img src={ellipse} className="display" alt="" />
                <img src={shape2} className="shape-4 display" alt="" />
              </div>
              <Row className="header-image">
                <Col>
                  <img src={teamImg} alt="Two developers photo" />
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section-background founder-section py-5">
            <Container>
              <Row className="middle-section">
                <Col md={6} style={{ position: "relative" }}>
                  <div style={{ zIndex: 100 }}>
                    <img src={teamImg1} alt="Company founders photo" />
                    <img src={waves} className="wave-1 display" alt="" />
                    <div className="ellipse1">
                      <img src={ellipse} className="display" alt="" />
                      <img src={shape4} className="shape-4 display" alt="" />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <h1 className="h1-info h1-image-header">
                    Stay ahead of the game
                  </h1>
                  <p className="p-info">
                    Hexowatch is your AI sidekick that helps you save time and
                    get alerts as soon as any product, price, competitor,
                    supplier or industry website changes.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="py-5">
            <Container>
              <Row className="text-center info-section">
                <Col>
                  <h1 className="h1-info">
                    We help businesses save up to 31+ hours a month
                  </h1>
                  <p className="p-header">
                    Manually checking multiple websites every day is
                    time-consuming, repetitive, and tedious. Hexowatch handles
                    this 24/7 on autopilot so you can focus on your business.
                  </p>
                </Col>
              </Row>
              <Row className="my-5 info-wrapper text-center">
                <img src={waves} className="wave-stats display" alt="" />
                <Col lg={4}>
                  <span>
                    <h1 className="h1-margin">32 hours</h1>
                    <p>Average time saved per user last month</p>
                  </span>
                </Col>
                <Col lg={4}>
                  <span>
                    <h1>30,728,402</h1>
                    <p>Checks performed last month</p>
                  </span>
                </Col>
                <Col lg={4}>
                  <span>
                    <h1>3088 GB</h1>
                    <p>Data processed last month</p>
                  </span>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section-background py-5">
            <Container>
              <Row className="middle-section">
                <Col md={6}>
                  <h1 className="h1-info h1-image-header">
                    Different perspectives gives better results
                  </h1>
                  <p className="p-info">
                    Our founding team is global bringing experience from
                    marketing, advertising, eCommerce, infrastructure, coding,
                    and theoretical physics.
                  </p>
                </Col>
                <Col md={6}>
                  <img src={waves} className="wave-2 display" alt="" />
                  <img src={teamImg2} alt="Two team members photo" />
                </Col>
              </Row>
            </Container>
          </section>

          <section>
            <Container className="team-block">
              <Row className="pt-5">
                {peoples.map((person, i) => {
                  return (
                    <Col key={i} lg={3} sm={6}>
                      <div className="ex_team_item">
                        <img src={person.img} alt="" className="img" />
                        <div className="team_content">
                          <h3 className="">{person.name}</h3>
                          <h5>{person.position}</h5>
                        </div>
                        <div className="hover_content">
                          <p className="name">{person.name}</p>
                          <p className="position">{person.position}</p>
                          <p>{person.description}</p>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>

              <Row className="text-center mb-5">
                <Col className="text-center px-5">
                  <h1 className="h1-info">Looking for your next challenge?</h1>
                  <p className="p-header">
                    We are on a mission to empower businesses with innovative
                    applications and that requires an ever expanding team of
                    ambitious change-makers!
                  </p>
                  <p className="p-header">
                    If you’re an A player and want to join a dynamic startup,
                    check out our latest vacancies below:
                  </p>
                </Col>
              </Row>

              <Row className="my-5">
                <Col className="d-flex align-items-center justify-content-center">
                  <Button
                    onClick={() =>
                      (window.location.href = "https://hexact.io/careers")
                    }
                  >
                    See current openings
                  </Button>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Layout>
    </div>
  );
};

export default Team;
